<script>
import appConfig from "@/app.config";
import { Modal } from "bootstrap";
import chart from "./chart.vue";
import moment from 'moment'

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    chart,
  },
  data() {
    return {
      members: [],
      Users: {},
      marketing_team_members: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchUsers: null,
      SearchMode: false,
      emps: [],
      new_roles: [],
      newRoles: [{}],
      addRoles: [{}],
      branches: [],
      user_info: {},
      new_pass: "",
      users_switch: "",
      user_status: 0,
      data: {},
      roles: [],
      marketing_survey_team_member_area_modal: {},
      districts: [],
      area_data: {
        target: "",

        marketing_district_id: "",
        marketing_survey_team_member_id: "",
        marketing_area_ids: [],
      },
      marketingAreas: [],
      teamMemberStatsModal: {},
      chart_data: {
        member: {},
        chart: {},
      },
      user: {},

      from:'',
              to:'',
              option:'month',
              month:'',
              week:null
    };
  },
  methods: {
    editUser() {
      console.log(this.user_info);
      this.http
        .put("marketing-team-members/", this.user_info.id, {
          new_roles: this.newRoles,
          name: this.user_info.name,
          email: this.user_info.email,
          branch: this.user_info.emp.branch.name,
        })
        .then(() => {
          this.get(this.page);
          console.log(this.newRoles);
        });
    },
    addMarketingSurveyTeamMember() {
      this.data.marketing_survey_id = this.$route?.params?.id;
      this.http
        .post("marketing-survey-team-members", this.data, null, true)
        .then((res) => {
          if (res.status) {
            this.popup.alert({ title: "", msg: "popups.success" });
            document.querySelector("#addModal .close-modal-btn").click();

            this.data = {};
            this.get(this.page);
          }
        });
    },
    search() {
      this.SearchMode = true;
      console.log(this.searchUsers);
      this.tot_pages = 0;
      this.http
        .post(
          "marketing-survey-team-members/by-survey-id/search",
          {
            limit: this.limit,
            page: this.page,
            search: this.searchUsers,
            col: "name",
            marketing_survey_id: this.$route?.params?.id,
          },
          null,
          true
        )
        .then((res) => {
          this.members = res.data;
        });
    },
    cancelSearchMode() {
      this.searchUsers = "";
      this.SearchMode = false;
      this.get(this.page);
    },
    getUsers() {
      this.http
        .post(
          "marketing-survey-team-members/by-survey-id",
          {
            id: this.$route.params?.id,
          },
          null,
          true
        )
        .then((res) => {
          this.members = res.data;
        });
    },
    getMarketingTeamMembers() {
      this.http.get("marketing-team-members", null, null, true).then((res) => {
        this.marketing_team_members = res.data;
      });
    },
    deleteUser(member) {
      var data = {
        title: "popups.deleteuser",
        msg: "popups.deletemsg",
        buttons: [
          {
            text: "Delete",
            closer: true,
            color: "danger",
            handler: () => {},
          },
          {
            text: "Cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.confirm(data).then(() => {
        console.log(member);
        this.http
          .delete("marketing-survey-team-members", member.id)
          .then(() => {
            this.get(this.page);
            this.getMarketingTeamMembers();
          });
      });
    },
    userInfo(member) {
      this.user_info = member;
      console.log(this.user_info);
    },
    editPass() {
      console.log(this.user_info.id);
      this.http.post(
        "marketing-team-members/update-password",
        {
          id: this.user_info.id,
          password: this.new_pass,
        },
        null,
        true
      );
    },

    get(page) {
      console.log(this.userstable);
      console.log({ route_param: this.$route.params?.id });
      this.http
        .post(
          "marketing-survey-team-members/by-survey-id/pagination",
          {
            limit: this.limit,
            page: page,
            marketing_survey_id: this.$route.params?.id,
          },
          null,
          true
        )
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.members = res.data;
        });
    },
    async openMarketingSurveyTeamMemberArea(member) {
      this.districts = [];
      this.area_data = {
        target: await this.getTargetForMember(member.id),
        marketing_district_id: "",
        marketing_survey_team_member_id: member.id,
        marketing_area_ids: [],
      };
      console.log("step 159 :", this.area_data);
      this.http
        .post("marketing-survey-areas/districts/pagination", {
          page: 1,
          marketing_survey_id: this.$route.params?.id,
        })
        .then((res) => {
          if (res.status) {
            this.districts = res.data;
            this.marketing_survey_team_member_area_modal.show();
          }
        });
    },

    getTargetForMember(id) {
      return new Promise((resolve) => {
        this.http
          .get("marketing-survey-team-member-areas/get-target-for-member", {
            marketing_survey_team_member_id: id,
          })
          .then((res) => {
            if (res.status && res.target) resolve(res.target);
            else resolve();
          });
      });
    },
    getMarketingTeamMemberAreaForMember(obj) {
      return new Promise((resolve) => {
        this.http
          .get(
            "marketing-survey-team-member-areas/marketing-survey-team-member",
            {
              marketing_survey_team_member_id:
                obj.marketing_survey_team_member_id,
              marketing_district_id: obj.marketing_district_id,
            }
          )
          .then((res) => {
            if (res.status) resolve(res.data);
          });
      });
    },

    async getMarketingAreas(id) {
      this.marketingAreas = [];

      this.http
        .get("marketing-survey-areas/by-district-id", {
          marketing_district_id: id,
          marketing_survey_id: this.$route.params?.id,
        })
        .then(async (res) => {
          this.marketingAreas = res.data;
          this.area_data.marketing_area_ids = (
            await this.getMarketingTeamMemberAreaForMember({
              marketing_survey_team_member_id:
                this.area_data.marketing_survey_team_member_id,
              marketing_district_id: id,
            })
          ).map((area) => area.marketing_area_id);
        });
    },

    changeMarketingSurveyTeamMemberArea(obj) {
      console.log(obj);
      this.http
        .post("marketing-survey-team-member-areas/add-or-delete", {
          marketing_area_id: obj?.marketing_area_id,
          marketing_survey_team_member_id:
            this.area_data.marketing_survey_team_member_id,
          target: obj?.target,
        })
        .then((res) => {
          console.log("step 246 :", res.data);

          // if(res.status)
          // this.get(this.page)
        });
    },
    async openStatistics(member) {
      this.teamMemberStatsModal.show();
      const chart_res = await this.http.get(
        "marketing-survey-processes/marketing-survey-team-member/interviews-count",
        { marketing_survey_team_member_id: member.id },
        null,
        true
      );

      if (chart_res.status) {
        this.chart_data.chart = chart_res?.data;
        this.chart_data.member = member;
        console.log("step :", this.chart_data);
      }
    },
  async getStatistics(member){

const chart_res = await this.http.get(
        "marketing-survey-processes/marketing-survey-team-member/interviews-count",
        { marketing_survey_team_member_id: member.id,from:this.from,to:this.to },
        null,
        true
      );

      if (chart_res.status) {
        this.chart_data.chart = chart_res?.data;
        this.chart_data.member = member;
        console.log("step :", this.chart_data);
      }
  },
     
     changeMonth(){
          this.from= moment(this.month).startOf('month').format('YYYY-MM-DD')
          this.to =moment(this.month).endOf('month').format('YYYY-MM-DD')
          console.log("step 124 :",this.from, this.to);
          
        },
        selectWeek(){
          const startOfMonth = moment(this.month).startOf('month')
          const startOfWeek =startOfMonth.clone().add((this.week-1)*7,'days')
          const endOfWeek =startOfWeek.clone().add('6','days')
          this.from = startOfWeek.format('YYYY-MM-DD')
          this.to= endOfWeek.format('YYYY-MM-DD')
        }

    // changeMarketingSurveyTeamMemberAreaTarget(obj){
    //   this.http.post('marketing-survey-team-member-areas')
    // }
  },
  created() {
    this.user = JSON.parse(localStorage?.user);

    this.get(1);
    this.getMarketingTeamMembers();
  },
  mounted() {
    this.teamMemberStatsModal = new Modal("#team-member-stats");
    this.marketing_survey_team_member_area_modal = new Modal(
      "#marketingSurveyTeamMemberAreaModal"
    );
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div>
        <div class="row align-items-center">
          <div class="col-6">
            <div
              class="search-box chat-search-box d-flex align-items-center"
              style="width: 250px; margin-inline-end: auto"
            >
              <div class="position-relative mx-3 d-flex align-items-center">
                <input
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
                  :placeholder="$t('users.searchplaceholder')"
                  v-model="searchUsers"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
                <button
                  @click="cancelSearchMode()"
                  v-if="SearchMode"
                  class="btn-close text-light"
                  style="position: absolute; top: 12px; left: 250px"
                ></button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn btn-light float-start"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              :disabled="
                user.roles.some(
                  (role) =>
                    role?.role?.code == 'marketing_survey_manager_no_edit'
                )
              "
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>
        <!-- end row -->

        <hr class="mb-4" />

        <div class="row">
          <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
            <thead>
              <tr
                class="text-light text-center"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">{{ $t("users.userstable.id") }}</th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.name") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.tel") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.email") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.rate") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.birthdate") }}
                </th>

                <th scope="col">{{ $t("users.userstable.operations") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(member, index) in members"
                :key="member"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ member?.marketing_team_member?.name }}</td>
                <td>{{ member?.marketing_team_member?.tel }}</td>
                <td>{{ member?.marketing_team_member?.email }}</td>
                <td>
                  {{
                    member?.marketing_team_member?.rate
                      ? member?.marketing_team_member?.rate
                      : "--"
                  }}
                </td>
                <td>
                  {{ member?.marketing_team_member?.birthdate?.split("T")[0] }}
                </td>

                <td class="d-flex justify-content-start">
                  <button
                    @click="openStatistics(member)"
                    class="btn btn-primary btn-sm me-2"
                  >
                    {{
                      $t(
                        "menu.menuitems.marketing.survey_details.tabs.questions_charts"
                      )
                    }}
                  </button>
                  <button
                    @click="openMarketingSurveyTeamMemberArea(member)"
                    class="btn btn-primary btn-sm me-2"
                  >
                    {{ $t("marketing.team_member.tabs.areas") }}
                  </button>

                  <button
                    class="btn btn-danger btn-sm me-2"
                    :class="{}"
                    @click="deleteUser(member)"
                    :disabled="
                      user.roles.some(
                        (role) =>
                          role?.role?.code == 'marketing_survey_manager_no_edit'
                      )
                    "
                  >
                    {{ $t("popups.delete") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--Start Add Modal-->
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addMarketingSurveyTeamMember()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.adduser") }}
              </h5>
            </div>

            <div class="modal-body">
              <label for="member">{{ $t("popups.add_team") }}</label>
              <select
                v-model="data.marketing_team_member_id"
                class="form-select mb-2"
                id="user_role"
              >
                <option
                  v-for="member in marketing_team_members"
                  :key="member.id"
                  :value="member.id"
                >
                  {{ member?.name }}
                </option>
              </select>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.add") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn close-modal-btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="marketingSurveyTeamMemberAreaModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modelTitleId"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("popups.add") }}</h5>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="" class="form-label">{{
                $t("marketing.team_member.target")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="
                  changeMarketingSurveyTeamMemberArea({
                    target: area_data.target,
                  })
                "
                :disabled="
                  user.roles.some(
                    (role) =>
                      role?.role?.code == 'marketing_survey_manager_no_edit'
                  )
                "
                v-model="area_data.target"
                aria-describedby="helpId"
                placeholder=""
              />
            </div>
            <div class="mb-3">
              <label for="" class="form-label">{{
                $t("marketing.districts")
              }}</label>
              <select
                @change="getMarketingAreas(area_data.marketing_district_id)"
                v-model="area_data.marketing_district_id"
                class="form-select form-select"
                name=""
                id=""
              >
                <option v-for="a in districts" :key="a.id" :value="a.id">
                  {{ a.name }}
                </option>
              </select>
            </div>

            <div v-if="area_data.marketing_district_id" class="mb-3">
              <div
                v-for="(a, index) in marketingAreas"
                :key="index"
                :value="a.marketing_area_id"
                class="form-check"
              >
                <input
                  :value="a.marketing_area_id"
                  @change="
                    changeMarketingSurveyTeamMemberArea({
                      marketing_area_id: a.marketing_area_id,
                      target: area_data.target,
                    })
                  "
                  :disabled="
                    user.roles.some(
                      (role) =>
                        role?.role?.code == 'marketing_survey_manager_no_edit'
                    )
                  "
                  class="form-check-input"
                  :class="{ 'float-end': $i18n.locale == 'en' ? false : true }"
                  type="checkbox"
                  v-model="area_data.marketing_area_ids"
                />
                <label
                  class="form-check-label"
                  :class="{
                    'float-end': $i18n.locale == 'en' ? false : true,
                    'me-4': $i18n.locale == 'en' ? false : true,
                  }"
                  for=""
                >
                  {{ a?.marketing_area?.name }}
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="marketing_survey_team_member_area_modal.hide()"
              type="button"
              class="btn btn-secondary"
            >
              {{ $t("popups.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Body -->
    <!-- if you want to close by clicking outside the modal, delete the last endpoint:data-bs-backdrop and data-bs-keyboard -->
    <div
      class="modal fade"
      id="team-member-stats"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalTitleId"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalTitleId">
              {{
                $t(
                  "menu.menuitems.marketing.survey_details.tabs.questions_charts"
                )
              }}
              - {{ chart_data?.member?.marketing_team_member?.name }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="container-fluid my-3">
              <div class="row" style="margin-top: 2rem">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <form @submit.prevent="getStatistics(chart_data?.member)" >

          <div class="row mb-3">
            <div class="col-auto">
                <label for="" class="form-label">{{$t('report.options')}}</label>
                <select
                  v-model="option"
                  class="form-select form-select-lg"
                  name=""
                  @change="week='';month:'';form:'';to:''"
                  id=""
                >
                  <option value="month" >{{$t('report.during_month')}}</option>
                  <option value="week">{{$t('report.during_week')}}</option>
                  <option value="period">{{$t('report.during_period')}}</option>
                </select>
            </div>
            <template v-if="option =='period'">
              
                
            <div class="col-auto">
                <label for="" class="form-label">{{$t('report.from')}}</label>
                <input
                v-model="from"
                  type="date"
                  class="form-control"
                  
                />
              
            </div>
            <div class="col-auto">
              <label for="" class="form-label">{{$t('report.to')}}</label>
                <input
                v-model="to"
                  type="date"
                  class="form-control"
                  
                />
              
            </div>
              
            </template>
            <template v-else-if="option=='month'">
            <div class="col-auto">

                 <label for="" class="form-label">{{$t('report.month')}}</label>
                <input
                v-model="month"
                @input="changeMonth()"
                  type="month"
                  class="form-control"
                  
                />
            </div>
              
              
            </template>
            <template v-else>
            <div class="col-auto">

                 <label for="" class="form-label">{{$t('report.month')}}</label>
                <input
                v-model="month"
                  type="month"
                  class="form-control"
                  
                />
            </div>
            <div class="col-auto">

              <label for="" class="form-label">{{$t('report.week')}}</label>
                <select
                  v-model="week"
                  @change="selectWeek()"
                  class="form-select"
                 
                >
                  <option  :value="1" >{{$t('report.first_week') }} </option>
                  <option :value="2">{{$t('report.second_week')}}</option>
                  <option :value="3">{{$t('report.third_week')}}</option>
                  <option :value="4">{{$t('report.fourth_week')}}</option>
                </select>

            </div>
              
              
            </template>
            <div class="col-auto mt-4">
              <button :disabled=" option =='period' ? !((from && to)  ) : option=='month' ? !(month) : !(week && month ) " type="submit" name="" id="" class="btn btn-primary"> {{$t('popups.submit')}}</button>
            </div>
          </div>

          </form>
                      <h4 class="card-title mb-4">
                        {{ $t("marketing.team_member.line_chart_title") }}
                      </h4>
                      <chart
                        v-if="
                          chart_data &&
                          chart_data.member.id &&
                          chart_data?.chart
                        "
                        :type="'line'"
                        :categories="chart_data?.chart?.categories"
                        :series="chart_data?.chart?.series"
                        :title="'Interviews count'"
                        x_title="الايام"
                        y_title="المقابلات"
                        series_title="المقابلات"
                        :yaxis_max="chart_data?.chart?.yaxis_max"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Optional: Place to the bottom of scripts -->

    <!--End Add Modal-->
  </div>
</template>
<!-- marketing-survey-team-members -->
