<script>
import { lineChartData } from "./chartData";
import { pieChart } from "./chartData";
export default {
  props: [
    "series",
    "categories",
    "title",
    "type",
    "x_title",
    "y_title",
    "series_title",
    "yaxis_max",
  ],
  data() {
    return {
      lineChartData:JSON.parse(JSON.stringify(lineChartData)),
      pieChart:JSON.parse(JSON.stringify(pieChart)),
    };
  },
  computed: {
    chartData() {
      return this.type == "line" ? this.lineChartData : this.pieChart;
    },
  },
  watch: {
    series() {
      if (this.type == "line") {
        console.log("line", this.y_title);
        this.lineChartData.series[0].data = this.series;
        this.lineChartData.series[0].name = this.series_title;
        this.lineChartData.chartOptions.xaxis.categories = this.categories;
        this.lineChartData.chartOptions.xaxis.title.text = this.x_title;
        this.lineChartData.chartOptions.yaxis.title.text = this.y_title;
        
        if (this.yaxis_max)
          this.lineChartData.chartOptions.yaxis.max = this.yaxis_max;

              console.log("step 58 :",this.lineChartData);
              
 if (this.$refs.chart && this.$refs.chart.chart) {
        // Access ApexCharts instance and update options
       console.log("Step 62 :",this.lineChartData.chartOptions);
       
       this.$refs.chart.chart.updateOptions(this.lineChartData.chartOptions, true, true);
      }

      } else if (this.type == "pie") {
        console.log("pie");
        this.pieChart.series = this.series;
        this.pieChart.chartOptions.labels = this.categories;
        console.log(this.pieChart);
      }
    },
  },
  created() {
    if (this.type == "line") {
      console.log("line");
      this.lineChartData.series[0].data = this.series;
      this.lineChartData.series[0].name = this.series_title;
      this.lineChartData.chartOptions.xaxis.categories = this.categories;
      this.lineChartData.chartOptions.xaxis.title.text = this.x_title;
      this.lineChartData.chartOptions.yaxis.title.text = this.y_title;
      if (this.yaxis_max)
        this.lineChartData.chartOptions.yaxis.max = Math.ceil(this.yaxis_max); 

 if (this.$refs.chart && this.$refs.chart.chart) {
        // Access ApexCharts instance and update options
       console.log("Step 62 :",this.$refs.chart.chart);
       
       this.$refs.chart.chart.updateOptions(this.lineChartData.chartOptions, true, true);
      }
      console.log("step 58 :",this.lineChartData);
      
    } else if (this.type == "pie") {
      console.log("pie");
      this.pieChart.series = this.series;
      this.pieChart.chartOptions.labels = this.categories;
      console.log(' step 59 :', this.pieChart);
    }
  },
};
</script>
<template>
  <apexchart       ref="chart" class="apex-charts"  height="320" :type="type" dir="rtl" :series="chartData.series"
    :options="chartData.chartOptions"></apexchart>
</template>

<style></style>
