<template>
  
  <div class="container-fluid my-3">

  <div class="row " style="margin-top: 2rem">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="getSurveyStatistics()" >

          <div class="row mb-3">
            <div class="col-auto">
                <label for="" class="form-label">{{$t('report.options')}}</label>
                <select
                  v-model="option"
                  class="form-select form-select-lg"
                  name=""
                  @change="week='';month:'';form:'';to:''"
                  id=""
                >
                  <option value="month" >{{$t('report.during_month')}}</option>
                  <option value="week">{{$t('report.during_week')}}</option>
                  <option value="period">{{$t('report.during_period')}}</option>
                </select>
            </div>
            <template v-if="option =='period'">
              
                
            <div class="col-auto">
                <label for="" class="form-label">{{$t('report.from')}}</label>
                <input
                v-model="from"
                  type="date"
                  class="form-control"
                  
                />
              
            </div>
            <div class="col-auto">
              <label for="" class="form-label">{{$t('report.to')}}</label>
                <input
                v-model="to"
                  type="date"
                  class="form-control"
                  
                />
              
            </div>
              
            </template>
            <template v-else-if="option=='month'">
            <div class="col-auto">

                 <label for="" class="form-label">{{$t('report.month')}}</label>
                <input
                v-model="month"
                @input="changeMonth()"
                  type="month"
                  class="form-control"
                  
                />
            </div>
              
              
            </template>
            <template v-else>
            <div class="col-auto">

                 <label for="" class="form-label">{{$t('report.month')}}</label>
                <input
                v-model="month"
                  type="month"
                  class="form-control"
                  
                />
            </div>
            <div class="col-auto">

              <label for="" class="form-label">{{$t('report.week')}}</label>
                <select
                  v-model="week"
                  @change="selectWeek()"
                  class="form-select"
                 
                >
                  <option  :value="1" >{{$t('report.first_week') }} </option>
                  <option :value="2">{{$t('report.second_week')}}</option>
                  <option :value="3">{{$t('report.third_week')}}</option>
                  <option :value="4">{{$t('report.fourth_week')}}</option>
                </select>

            </div>
              
              
            </template>
            <div class="col-auto mt-4">
              <button :disabled=" option =='period' ? !((from && to)  ) : option=='month' ? !(month) : !(week && month ) " type="submit" name="" id="" class="btn btn-primary"> {{$t('popups.submit')}}</button>
            </div>
          </div>

          </form>
          <h4 class="card-title mb-4">
            {{ $t("marketing.team_member.line_chart_title") }}
          </h4>
          <!-- 
          [
        { x: new Date('2024-01-01'), y: 0 },
        { x: new Date('2024-01-02'), y: 0 },
        { x: new Date('2024-01-03'), y: 1 },
        { x: new Date('2024-01-04'), y: 0 },
        { x: new Date('2024-01-05'), y: 6 },
        { x: new Date('2024-01-06'), y: 7 },
        { x: new Date('2024-01-07'), y: 0 },
      ]
           -->
          <chart  :type="'line'" v-if="chart_data" :categories="chart_data?.categories" :series="chart_data?.series"
            :title="'Interviews count this week'" x_title="الأيام" y_title="المقابلات" series_title="المقابلات"
            :yaxis_max="chart_data?.yaxis_max" />
        </div>
      </div>
    </div>
  </div>
  </div>


</template>

<script>
import chart from "./chart.vue";
import moment from 'moment'
export default {
props:['surveyChartObject'],
data() {
    return {
              chart_data: null,
              from:'',
              to:'',
              option:'month',
              month:'',
              week:null

    }
},
    components:{
        chart
    },
    methods: {
      async  getSurveyStatistics(){

const chart_res = await this.http.post("marketing-survey-processes/survey-interviews-count", { marketing_survey_id: this.$route.params.id,from:this.from,to:this.to }, null, true);
      this.chart_data = chart_res?.data;
        },
        changeMonth(){
          this.from= moment(this.month).startOf('month').format('YYYY-MM-DD')
          this.to =moment(this.month).endOf('month').format('YYYY-MM-DD')
          console.log("step 124 :",this.from, this.to);
          
        },
        selectWeek(){
          const startOfMonth = moment(this.month).startOf('month')
          const startOfWeek =startOfMonth.clone().add((this.week-1)*7,'days')
          const endOfWeek =startOfWeek.clone().add('6','days')
          this.from = startOfWeek.format('YYYY-MM-DD')
          this.to= endOfWeek.format('YYYY-MM-DD')
        }
    },
    mounted() {
        // this.getSurveyStatistics()
        // eslint-disable-next-line vue/no-mutating-props
        this.surveyChartObject.refresh = this.getSurveyStatistics
    },
    

}
</script>

<style>

</style>